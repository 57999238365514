import {
  Box,
  Button,
  Divider,
  TextField,
  useTheme,
  Typography,
  IconButton,
  TextareaAutosize,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  ButtonGroup,
  useMediaQuery,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  RadioGroup,
  FormLabel,
  Radio,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CloseIcon from "@mui/icons-material/Close";
import FlagIcon from "@mui/icons-material/Flag";
import "react-image-crop/dist/ReactCrop.css";
import DeleteIcon from "@mui/icons-material/Delete";

import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { tokens } from "../../../theme";
import { getAuthentication } from "../../../auth";
import { GLOBAL_API } from "../../../data/Links";
const API_URL = `${GLOBAL_API}/dashapi`;
const CountryGQLSchema = `
id
Name{
    En
    Ar
}
Enable
PhoneCode
Shortcut
`;

const SportSeasonGQLSchema = `
id
Year
GamesCounts{
  SixBetGame
  SportBetJackPot
}
`;
const SportGQLSCHEMA = `
id
Name{
    En
    Ar
}
Slug
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}
`;
const CompetitorsGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
Name{
    En
    Ar
}
isTeam
Country{
    ${CountryGQLSchema}
}
MainImage{
    FilePath
}
`;
const CompetitionsGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        Ar
        En
    }
    Gender
}
MainImage{
    FilePath
}
Seo{
    Keywords
    Description
}    
Infos{
    CreatedAt
}
`;
const SportEventsGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
Compitiors{
    Home{
        ${CompetitorsGQLSchema}
    }
    Away{
        ${CompetitorsGQLSchema}
    }
}
Year{
    ${SportSeasonGQLSchema}
}
Competition{
    ${CompetitionsGQLSchema}
}
Venue
StartTime
isLive
Ended
Score{
    Home
    Away
}
`;
const SoccerWinPredictionGQLSchema = `
id
Sport{
    ${SportGQLSCHEMA}
}
MainInfo{
    Name{
        En
        Ar
    }
    Slug
    StartDate
    Composition{
        ${CompetitionsGQLSchema}
    }
}
MainImage{
    FilePath
}
SportEvents{
   ${SportEventsGQLSchema}
}
Participants{
    id
}
Awards{
    Index
    isWin
    Award
}
Infos{
    SportEventsReady
    ParticipantsCount
    Published
    Locked
    CreatedAt
}
`;

const SportEvents = ({
  competition,
  handleSubmit,
  handlePull,
  setAsDone,
  closingModal,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loadedSeasons, setLoadedSeasons] = useState([]);
  const [schedual, setSchedual] = useState([]);
  const [sportEvents, setSportEvents] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);

  const handleAddSportEvent = () => {
    handleSubmit(selectedMatch.id);
  };

  const deleteEvent = (qa) => {
    handlePull(qa.id);
  };

  const handleSetAsDone = () => {
    const confirmed = window.confirm(
      "Are you sure you want to perform this action? This change cannot be undone."
    );
    if (confirmed) {
      setAsDone();
      closingModal();
    }
  };

  const getMatches = async () => {
    const res = await axios.post(
      API_URL,
      {
        query: `
                query{
                    GetAllEvents(sport:"${competition.Sport.id}",competition:"${competition.MainInfo.Composition.id}",season:"${competition.MainInfo.Season.id}"){
                        ${SportEventsGQLSchema}
                    }
                }
            `,
      },
      {
        headers: {
          token: `Bearer ${getAuthentication()}`,
        },
      }
    );
    if (res.data.data.GetAllEvents) {
      setSportEvents(res.data.data.GetAllEvents);
    }
  };

  useEffect(() => {
    getMatches();
  }, [competition]);
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        height: "90%",
        bgcolor: colors.primary[400],
        overflowY: "scroll",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={() => closingModal()} color="error">
          <CloseIcon />
        </IconButton>
      </Box>

      <Typography
        variant="h3"
        color="secondary"
        sx={{
          textAlign: "center",
        }}
      >
        Sport Events
      </Typography>
      <Typography sx={{ textAlign: "center" }} variant="h6">
        {competition.MainInfo.Name.En} - {competition.MainInfo.Name.Ar}
      </Typography>
      <br />
      <Divider />
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={competition.Infos.SportEventsReady}
              color="secondary"
              disabled={competition.Infos.SportEventsReady}
              onChange={() => handleSetAsDone()}
            />
            <Typography>Set Sport Event is ready</Typography>
          </Box>
          <TableContainer>
            <Typography>
              {" "}
              Sport Events Count {competition.SportEvents.length}
            </Typography>

            <Table>
              <TableBody>
                {competition.SportEvents.map((sE) => (
                  <TableRow key={sE.EventId}>
                    <TableCell>
                      <Typography variant="h6">
                        {new Date(sE.StartTime).toLocaleString()}
                      </Typography>
                      <Typography variant="h6">
                        Date in UTC: {sE.StartTime}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {sE.Compitiors.Home.Name.En} -{" "}
                      {sE.Compitiors.Home.Name.Ar}
                    </TableCell>
                    <TableCell>
                      {sE.Compitiors.Away.Name.En} -{" "}
                      {sE.Compitiors.Away.Name.Ar}
                    </TableCell>
                    {!competition.Infos.SportEventsReady && (
                      <TableCell>
                        <IconButton
                          onClick={() => deleteEvent(sE)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default SportEvents;
